(function () {
	angular.module('Plania').controller('PurchaseOrderController', ['$scope', 'Repository', '$stateParams', '$modal', '$rootScope', 'ngTableParams', '$window', 'TranslationService', '$q', '$localStorage', 'ListService', '$timeout', controller]);

	function controller($scope, repository, $stateParams, $modal, $rootScope, ngTableParams, $window, translationService, $q, $localStorage, listService, $timeout) {
		$scope.isUpdate = $scope.navigation.current.name === 'purchaseOrder.edit';
		$scope.model = { Guid: $stateParams.guid };
		$scope.activateAutocomplete = false;
		$scope.modelDto = {};
		$scope.viewMode = $scope.isUpdate ? true : false;
		$scope.isPurchaseOrderClosedViaProject = false;
		$scope.allRowsSelected = false;
		
		
		$scope.toggleViewMode = function () {
			$scope.viewMode = !$scope.viewMode;
		};

		$scope.documents = [];
		$scope.alarmLogs = [];

		$scope.isDocumentsCollapsed = true;
		$scope.reloadDocumentTable = false;
		$scope.forceReloadDocumentTable = false;
		$scope.reloadAlarmLogTable = false;

		$scope.counts = {
			documentTotalCount: 0
		};

		$scope.validCharacters = '^(?!.*<[a-zA-ZæøåÆØÅ]+).*';

		$scope.getPropertyValue = function (entity, field) {
			return listService.GetPropertyValue(entity, field);
		};

		$scope.selectedItems = [];

		$scope.itemIsSelected = function (guid) {
			return _.contains($scope.selectedItems, guid);
		};

		$scope.selectAllRows = function () {
			if ($scope.allRowsSelected) {
				$scope.purchaseOrderItemTable.data.forEach(function (row) {
					var index = _.findIndex($scope.selectedItems, function (o) { return o === row.Guid; });
					$scope.selectedItems.splice(index, 1);
				});

				$scope.allRowsSelected = false;
			} else {
				$scope.purchaseOrderItemTable.data.forEach(function (row) {
					if (!_.contains($scope.selectedItems, row.Guid)) {
						$scope.selectedItems.push(row.Guid);
					}
				});
				$scope.allRowsSelected = true;
			}
		};

		$scope.addItem = function (guid) {
			if (!_.contains($scope.selectedItems, guid))
				$scope.selectedItems.push(guid);
			else {
				for (var i = 0; i < $scope.selectedItems.length; i++) {
					if ($scope.selectedItems[i] === guid) {
						$scope.selectedItems.splice(i, 1);
						return;
					}
				}
			}
		};

		$scope.receiveSelectedItems = function () {
			if ($scope.selectedItems.length === 0) {
				swal(translationService.translate('web-no-choice-header', 'Ingen valg'),
					translationService.translate('purchase-order-purchase-order-item-list-noSelect', 'Ingen reservedeler valgt. Velg en eller flere fra listen for å motta'),
					'error');
				return;
			}

			var nrSuccessCalls = 0;

			swal({
				title: translationService.translate('web-swal-purchase-order-item-receive', 'Bekreft mottak av reservedeler'),
				text: translationService.translate('web-swal-purchase-order-item-receive-message', 'Er du sikker på at du vil motta reservedeler?'),
				type: "info",
				showCancelButton: true,
				confirmButtonColor: "#2196f3",
				confirmButtonText: translationService.translate('web-swal-purchase-order-item-receive-confirm', 'Motta reservedeler'),
				cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
				closeOnConfirm: false,
				showLoaderOnConfirm: true
			}, function () {
				$scope.selectedItems.forEach(function (item) {
					updateItem({ Guid: item, RequestAction: 'ReceiveItem' });
				});
				$scope.reloadMainEntity();
			});

			var updateItem = function (payload) {
				repository.updateSingleDictionary(repository.apiData.purchaseOrderItem.url, payload).then(function (result) {
					nrSuccessCalls++;
					if (nrSuccessCalls === $scope.selectedItems.length) {
						swal(translationService.translate('web-swal-purchase-order-item-receive-success', 'Reservedelene er mottatt!'), '', "success");
						$scope.purchaseOrderItemTable.reload();
					}
				});
			};
		};

		$scope.receiveItem = function (item) {
			swal({
				title: translationService.translate('web-swal-purchase-order-item-receive', 'Bekreft mottak av reservedeler'),
				text: translationService.translate('web-swal-purchase-order-item-receive-message', 'Er du sikker på at du vil motta reservedeler?'),
				type: "info",
				showCancelButton: true,
				confirmButtonColor: "#2196f3",
				confirmButtonText: translationService.translate('web-swal-purchase-order-item-receive-confirm', 'Motta reservedeler!'),
				cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
				closeOnConfirm: false,
				showLoaderOnConfirm: true
			}, function () {
				var payload = {
					Guid: item,
					RequestAction: 'ReceiveItem'
				};
					repository.updateSingleDictionary(repository.apiData.purchaseOrderItem.url, payload).then(function (result) {
					swal(translationService.translate('web-swal-purchase-order-item-receive-success', 'Reservedelene er mottatt!'), result.Id, "success");
						$scope.purchaseOrderItemTable.reload();
						$scope.reloadMainEntity();
				}, function (error) {
					swal(translationService.translate('web-error', "Feil"), error, "error");
				});
			});
		};

		$scope.receiveItemPartially = function (guid) {
			$modal.open({
				templateUrl: 'app/PurchaseOrder/views/ReceivePartiallyModal.html',
				controller: 'PartiallyReceiveItemController',
				resolve: {
					params: function () {
						return {
							Guid: guid
						};
					}
				}
			}).result.then(function (item) {
				repository.updateSingleDictionary(repository.apiData.purchaseOrderItem.url, item)
					.then(function (result) {
						repository.growl(translationService.translate('web-purchase-order-item-updated', 'Objektet er oppdatert'), 'success');
						$scope.purchaseOrderItemTable.reload();
						$scope.reloadMainEntity();
					});
			});
		};

		var updateAccess = function () {
			if ($scope.isUpdate) {
				var checkOtherDo = $scope.model.GuidDataOwner !== repository.commonService.getFilterData().selectedDataOwner.Guid;
				$scope.restrictEdit = !repository.authService.hasEditAccess(repository.commonService.prefix.PurchaseOrder, checkOtherDo);
				$scope.restrictDelete = !repository.authService.hasDeleteAccess(repository.commonService.prefix.PurchaseOrder, checkOtherDo);
				$scope.canReceive = $localStorage.generalOptions.UseReception && ($scope.model.Status === 'Ordered' || $scope.model.Status === 'PartiallyReceived');
			} else {
				$scope.restrictEdit = !repository.authService.hasCreateAccess(repository.commonService.prefix.PurchaseOrder);
			}
		};

		updateAccess();


		var getCardTables = function () {
			$scope.purchaseOrderItemTable = new ngTableParams(
				{
					page: 1,
					count: 10,
					sorting: { 'Id': 'asc' },
					filter: { PropertyFilter: [{ Property: 'GUID_PURCHASE_ORDER', Operator: '=', Value: $scope.model.Guid }] }
				},
				{
					total: 0,
					counts: [10, 20, 50],
					filterDelay: 10,
					paginationMaxBlocks: 6,
					getData: function ($defer, params) {
						var columns = [];
						$scope.purchaseOrderItemColumns.forEach(function (col) {
							columns.push(col.Property);
						});

						columns.push('WorkOrder.Project.IsCompleted');

						repository.GetPaginated(repository.apiData.purchaseOrderItem.url, params.page() - 1, params.count(), params.sorting(), params.filter(), "", JSON.stringify(columns))
							.then(function (result) {

								result.List.forEach(function (item) {
									if (item.WorkOrder && item.WorkOrder.Project && item.WorkOrder.Project.IsCompleted)
										$scope.isPurchaseOrderClosedViaProject = true;
								});

								$scope.purchaseOrderItemTable.settings().total = result.TotalCount;
								$scope.purchaseOrderItemTable.settings().filterDelay = 500;
								$defer.resolve(result.List);
							},
								function (error) {
									repository.growl(error, 'danger');
								});
					}
				});


			//$scope.costTable = new ngTableParams(
			//	{
			//		page: 1,
			//		count: 10,
			//		sorting: { 'InvoiceNumber': 'desc' },
			//		filter: { PropertyFilter: [{ Property: 'GUID_PURCHASE_ORDER', Operator: '=', Value: $scope.model.Guid }] }
			//	},
			//	{
			//		total: 0,
			//		counts: [10, 20, 50],
			//		filterDelay: 10,
			//		paginationMaxBlocks: 6,
			//		getData: function ($defer, params) {
			//			var columns = [];
			//			$scope.costColumns.forEach(function (col) {
			//				columns.push(col.Property);
			//			});

			//			repository.GetPaginated(repository.apiData.cost.url, params.page() - 1, params.count(), params.sorting(), params.filter(), "", JSON.stringify(columns))
			//				.then(function (result) {
			//					$scope.costTable.settings().total = result.TotalCount;
			//					$scope.costTable.settings().filterDelay = 500;
			//					$defer.resolve(result.List);
			//				},
			//				function (error) {
			//					repository.growl(error, 'danger');
			//				});
			//		}
			//	});
		};

		var columns = [
			'Building.Id', 'Building.Description',
			'Building.GuidCostCenter',
			'Equipment.Id', 'Equipment.Description',
			'Area.Id', 'Area.Description',
			'ContactPerson.FirstName', 'ContactPerson.LastName', 'ContactPerson.Email',
			'Customer.Id', 'Customer.Description',
			'DeliveryTerm.Id', 'PaymentTerm.Id',
			'PurchaseOrderForm.Description', 'PurchaseOrderForm.Report.Id', 'PurchaseOrderForm.GuidReport',
			'Supplier.Id', 'Supplier.Description', 'Supplier.Telephone', 'Supplier.Email',
			'User.RealName', 'User1.RealName', 'User2.RealName', 'User3.RealName'
		];

		var setProperties = function () {
			var purchaseOrder = $scope.model;
			if (purchaseOrder.Building) $scope.modelDto.building = $scope.getEntityCaption(purchaseOrder.Building);
			if (purchaseOrder.Equipment) $scope.modelDto.equipment = $scope.getEntityCaption(purchaseOrder.Equipment);
			if (purchaseOrder.Area) $scope.modelDto.area = $scope.getEntityCaption(purchaseOrder.Area);
			if (purchaseOrder.ContactPerson) $scope.modelDto.contactPerson = purchaseOrder.ContactPerson.FirstName + ' ' + purchaseOrder.ContactPerson.LastName;
			if (purchaseOrder.Customer) $scope.modelDto.customer = $scope.getEntityCaption(purchaseOrder.Customer);
			if (purchaseOrder.DeliveryTerm) $scope.modelDto.deliveryTerm = purchaseOrder.DeliveryTerm.Id;
			if (purchaseOrder.PaymentTerm) $scope.modelDto.paymentTerm = purchaseOrder.PaymentTerm.Id;
			if (purchaseOrder.PurchaseOrderForm) $scope.modelDto.purchaseOrderForm = purchaseOrder.PurchaseOrderForm.Description;
			if (purchaseOrder.Supplier) $scope.modelDto.supplier = $scope.getEntityCaption(purchaseOrder.Supplier);

			//PrintedBy
			if (purchaseOrder.User) {
				$scope.modelDto.userPrintedBy = purchaseOrder.User.RealName;
			}

			//ReceivedBy
			if (purchaseOrder.User1) {
				$scope.modelDto.userReceivedBy = purchaseOrder.User1.RealName;
			}

			//UpdatedBy
			//if (purchaseOrder.User2) {
			//    $scope.modelDto.userUpdatedBy = purchaseOrder.Supplier.Id + (purchaseOrder.Supplier.Description ? ' - ' + purchaseOrder.Supplier.Description : '');
			//}

			//CreatedBy
			if (purchaseOrder.User3) {
				$scope.modelDto.userCreatedBy = purchaseOrder.User3.RealName;
			}
		};

		if ($scope.isUpdate) {
			repository.getMainModel(repository.apiData.purchaseOrder.url, $stateParams.guid, JSON.stringify(columns)).then(
				function (response) {
					$scope.model = response.Data;
					$scope.reloadDocumentTable = true;

					updateAccess();

					setProperties();
					getCardTables();

					setTimeout(function () { $scope.activateAutocomplete = true; }, 250);

				},
				function (error) {
					repository.growl(error, 'danger');
					$scope.activateAutocomplete = true;
				}
			);
		} else {
			var date = new Date();
			$scope.model.BudgetDate = new Date(date.getFullYear(), date.getMonth(), 1);
			$scope.model.IsLockedProposal = $localStorage.generalOptions.CanLockPoProposal;
			$scope.modelGuidPurchaseOrderForm = $localStorage.generalOptions.GuidDefaultOrderForm;
			$scope.activateAutocomplete = true;
		}

		var updateSuccess = function (result, destination) {
			repository.growl($scope.isUpdate ? translationService.translate('update-purchaseOrder-success', 'Bestilling har blitt oppdatert.') : translationService.translate('create-purchaseOrder-success', 'Bestilling har blitt opprettet.'), 'success');
			var guid = $scope.isUpdate ? $scope.model.Guid : result.Guid;
			repository.commonService.setLastRegisterGuid(destination, guid);
			$scope.goBack(destination, { guid: guid, menuGuid: $scope.navigation.params.menuGuid });
		};

		var updateError = function (error) {
			if (typeof (error) === "string")
				repository.growl(error, 'danger');
			else
				repository.growl(error.Data.Message, 'danger');
		};

		$scope.update = function (destination) {
			var success = function (result) { updateSuccess(result, destination); };
			if ($scope.isUpdate)
				repository.updateSingleDictionary(repository.apiData.purchaseOrder.url, $scope.model).then(success, updateError);
			else
				repository.createSingleDictionary(repository.apiData.purchaseOrder.url, $scope.model).then(success, updateError);
		};

		$scope.delete = function () {
			swal({
				title: translationService.translate('web-swal-error-areyousure', 'Er du sikker?'),
				text: translationService.translate('web-swal-purchaseOrder-message', "Bestilling vil bli permanent fjernet!"),
				type: "warning",
				showCancelButton: true,
				confirmButtonColor: "#f44336",
				confirmButtonText: translationService.translate('web-swal-purchaseOrder-button-confirm', 'Ja, fjern bestilling'),
				cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
				closeOnConfirm: false,
				showLoaderOnConfirm: true
			}, function () {
				window.onkeydown = null;
				window.onfocus = null;
				repository.deleteSingle(repository.apiData.purchaseOrder.url, $scope.model.Guid)
					.then(function (result) {
						swal(translationService.translate('web-swal-purchaseOrder-success', 'Bestilling ble fjernet!'), result, "success");
						$scope.goBack('purchaseOrder.list', { menuGuid: $scope.navigation.params.menuGuid });
					});
			});
		};

		$scope.reloadMainEntity = function () {
			var deferred = $q.defer();
			repository.getMainModel(repository.apiData.purchaseOrder.url, $stateParams.guid, JSON.stringify(columns)).then(
				function (response) {
					$scope.model = response.Data;
					deferred.resolve(response);
				},
				function (error) {
					deferred.reject(error);
				}
			);
			return deferred.promise;
		};

		$scope.getEntityCaption = function (item) {
			return item.Id + ' '+item.Description;
		};

		// Purchase order items
		$scope.purchaseOrderItemColumns = [];
		$scope.purchaseOrderItemColumns.AddColumn = function (translationId, defaultText, property, propertyType) {
			this.push({ Position: this.length, Title: translationService.translate(translationId, defaultText), Property: property, PropertyType: propertyType });
			return this;
		};

		$scope.purchaseOrderItemColumns
			.AddColumn('web-purchaseOrderItem-Id', "Id", 'Id', 'string')
			.AddColumn('web-purchaseOrderItem-Description', "Beskrivelse", 'Description', 'string')
			.AddColumn('web-workOrder-Id', "AO Id", 'WorkOrder.Id', 'number')
			.AddColumn('web-supplier-description', "Leverandør", 'Supplier.Description', 'string')
			.AddColumn('web-purchaseOrderItem-OrderedQuantity', "Bestilt antall", 'OrderedQuantity', 'number')
			.AddColumn('web-purchaseOrderItem-UnitPrice', "Enhetspris", 'UnitPrice', 'double')
			.AddColumn('web-purchaseOrderItem-OrderedAmount', "Bestilt beløp", 'OrderedAmount', 'double')
			.AddColumn('web-purchaseOrderItem-Status', "Status", 'Status', 'enum.purchaseorderitemstatus');


		// Purchase order items
		$scope.costColumns = [];
		$scope.costColumns.addColumn = function (translationId, defaultText, property, propertyType) {
			this.push({ Position: this.length, Title: translationService.translate(translationId, defaultText), Property: property, PropertyType: propertyType });
			return this;
		};

		$scope.costColumns
			.addColumn('web-cost-InvoiceNumber', "Fakturanr.", 'InvoiceNumber', 'string')
			.addColumn('web-account-id', "Konto", 'Account.Id', 'string')
			.addColumn('web-account-description', "Beskrivelse", 'Account.Description', 'string')
			.addColumn('web-cost-amount', "Beløp", 'Amount', 'double')
			.addColumn('web-cost-InvoiceDate', "Fakturadato", 'InvoiceDate', 'date')
			.addColumn('web-supplier-description', "Leverandør", 'Supplier.Description', 'string')
			.addColumn('web-cost-vouchernumber', "Bilagsnr", 'VoucherNumber', 'string');

		//CRUD
		$scope.addPurchaseOrderItem = function () {
			if (!$scope.model.GuidSupplier) {
				repository.growl(translationService.translate('web-purchaseOrderItem-Create-PurchaseOrderWithoutSupplier', 'Du må velge leverandør for bestillingen før du kan opprette bestillingsobjekter'), 'danger');
				return false;
			}

			$modal.open({
				templateUrl: 'app/purchaseOrder/views/purchaseOrderItemModal.html',
				controller: 'PurchaseOrderItemModalController',
				size: 'md', // always md when adding new item
				resolve: {
					params: function () {
						return {
							purchaseOrderItem: {
								GuidPurchaseOrder: $scope.model.Guid,
								GuidBuilding: $scope.model.GuidBuilding,
								GuidSupplier: $scope.model.GuidSupplier,
								Supplier: $scope.model.Supplier,
								GuidDataOwner: repository.commonService.getFilterData().selectedDataOwner.Guid,
								GuidCostCenter: $scope.model.Building.GuidCostCenter,
							}
						};
					}
				}
			}).result.then(function () {
				$scope.purchaseOrderItemTable.reload();
				$scope.reloadMainEntity();// in case amount must be refreshed
			});
		};

		$scope.updatePurchaseOrderItem = function (guid) {

			if (!$scope.hasEditAccess("PurchaseOrderItem"))
				return;

			$modal.open({
				templateUrl: 'app/purchaseOrder/views/purchaseOrderItemModal.html',
				controller: 'PurchaseOrderItemModalController',
				size: 'xl',
				resolve: {
					params: function () {
						return {
							Guid: guid
						};
					}
				}
			}).result.then(function () {
				$scope.purchaseOrderItemTable.reload();
				$scope.reloadMainEntity();// in case amount must be refreshed
			});
		};

		$scope.removePurchaseOrderItem = function (purchaseOrderItemGuid) {
			swal({
				title: translationService.translate('web-swal-error-areyousure', 'Er du sikker?'),
				text: translationService.translate('web-swal-purchaseOrderItem-message', 'Bestilling objektet vil bli permanent fjernet!'),
				type: "warning",
				showCancelButton: true,
				confirmButtonColor: "#f44336",
				confirmButtonText: translationService.translate('web-swal-purchaseOrderItem-button-confirm', 'Ja, fjern bestilling objektet!'),
				cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
				closeOnConfirm: false,
				showLoaderOnConfirm: true
			}, function () {
				window.onkeydown = null;
				window.onfocus = null;
				repository.deleteSingle(repository.apiData.purchaseOrderItem.url, purchaseOrderItemGuid)
					.then(function (result) {
						swal(translationService.translate('web-swal-purchaseOrderItem-success', 'Bestilling objektet ble fjernet!'), result, "success");
						repository.growl('Bestilling objektet er fjernet fra bestillingen', 'success');
						$scope.purchaseOrderItemTable.reload();
						$scope.reloadMainEntity();// in case amount must be refreshed
					});
			});
		};
		//END purchase order items

		//Cost
		$scope.updatCost = function (guid) {
			$modal.open({
				templateUrl: 'app/purchaseOrder/views/purchaseOrderItemModal.html',
				controller: 'PurchaseOrderItemModalController',
				size: 'xl',
				resolve: {
					params: function () {
						return {
							Guid: guid
						};
					}
				}
			}).result.then(function () {
				$scope.purchaseOrderItemTable.reload();
			});
		};

		$scope.removeCost = function (costGuid) {
			swal({
				title: translationService.translate('web-swal-error-areyousure', 'Er du sikker?'),
				text: translationService.translate('web-swal-purchaseOrderItem-message', 'Bestilling objektet vil bli permanent fjernet!'),
				type: "warning",
				showCancelButton: true,
				confirmButtonColor: "#f44336",
				confirmButtonText: translationService.translate('web-swal-cost-button-confirm', 'Ja, fjern kostnad!'),
				cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
				closeOnConfirm: false,
				showLoaderOnConfirm: true
			}, function () {
				window.onkeydown = null;
				window.onfocus = null;
				repository.deleteSingle(repository.apiData.cost.url, costGuid)
					.then(function (result) {
						swal(translationService.translate('web-swal-cost-success', 'Kostnad ble fjernet!'), result, "success");
						repository.growl('Kostnad er fjernet fra bestillingen', 'success');
						$scope.costTable.reload();
					});
			});
		};

		//supplier
		$scope.onSupplierSelect = function (supplier) {
			if (!supplier) {
				$scope.model.GuidSupplier = '';
				return;
			}


			if ($scope.model.GuidSupplier !== supplier.Guid) {
				$scope.model.GuidContactPerson = '';
				$scope.modelDto.contactPerson = '';
			}

			$scope.model.GuidSupplier = supplier.Guid;
			$scope.model.Supplier = supplier;
		};

		$scope.autoCompleteFilter = function (filterName) {
			var filter = {};
			switch (filterName) {
				case "contactPerson":
					filter.PropertyFilter = [{ Property: 'GuidSupplier', Operator: '=', Value: $scope.model.GuidSupplier }];
					break;
				case "equipment":
					filter.PropertyFilter = [];
					filter.PropertyFilter.push({ Property: 'IsTemplate', Operator: '=', Value: false });
					if ($scope.model.GuidBuilding)
						filter.PropertyFilter.push({ Property: 'GuidBuilding', Operator: '=', Value: $scope.model.GuidBuilding });
					break;
				case "area":
					if ($scope.model.GuidBuilding)
						filter.PropertyFilter = [{ Property: 'GuidBuilding', Operator: '=', Value: $scope.model.GuidBuilding }];
					break;
				case "supplier":
					filter.PropertyFilter = [
						{ Property: 'IsDeactivated', Operator: '=', Value: 'false' },
						{ Property: 'HasNotRequisition', Operator: '=', Value: 'false' }];
					break;
			}
			return filter;
		};

		$scope.issueOrder = function () {
			// Issue order is currently shown when the table has loaded. Find first workOrderGuid in order to use it for potential activation. API handles if activation is possible.
			var guidWorkOrder = $scope.purchaseOrderItemTable.data[0].GuidWorkOrder;

			// ensure properties are up-to-date (among others, amounts) before issuing the order
			$scope.reloadMainEntity()
				.then(function (response) {
					$modal.open({
						templateUrl: 'app/purchaseOrder/views/issuePurchaseOrderModal.html',
						controller: 'IssuePurchaseOrderModalController',
						size: 'md',
						resolve: {
							params: function () {
								return {
									model: $scope.model,
									guidWorkOrder: guidWorkOrder
								};
							}
						}
					}).result.then(function (response) {
						if (response === 'changeStatus') {
							var postModel = angular.copy($scope.model);
							postModel.RequestAction = 'Issue';
							var success = function (result) { updateSuccess(result, 'purchaseOrder.edit'); };
							repository.updateSingleDictionary(repository.apiData.purchaseOrder.url, postModel).then(success, updateError);
						} else {
							startEmailDialogWithAttachedDocument(response);
						}
					}, function (error) {
						if (error)
							repository.growl(error, 'danger');
					});
				});
		};

		var mailAddress = function (emailAddress) {
			return { Name: emailAddress, Email: emailAddress, DisplayProperty: emailAddress + new Date().valueOf() };
		};

		var startEmailDialogWithAttachedDocument = function (docXEntity) {
			var emailPrefill = {
				body: '',
				subject: 'Bestilling ' + $scope.model.Caption
			};

			var contactPersonEmail = $scope.model.ContactPerson && $scope.model.ContactPerson.Email ? mailAddress($scope.model.ContactPerson.Email) : null;
			var supplierEmail = $scope.model.Supplier && $scope.model.Supplier.Email ? mailAddress($scope.model.Supplier.Email) : null;
			if (contactPersonEmail) {
				emailPrefill.to = [contactPersonEmail];
				emailPrefill.copy = supplierEmail ? [supplierEmail] : [];
			} else {
				emailPrefill.to = supplierEmail ? [supplierEmail] : [];
				emailPrefill.copy = [];
			}

			var onNewDocument = function () {
				$scope.forceReloadDocumentTable = false;
				$timeout(function () {
					$scope.forceReloadDocumentTable = true;
				}, 250);
			};

			$modal.open({
				templateUrl: 'app/common/views/emailModal.html',
				controller: 'EmailModalController',
				size: 'lg',
				resolve: {
					params: function () {
						return {
							entity: 'PurchaseOrder',
							columns: columns,
							model: $scope.model,
							attachments: $scope.documents,
							preselectedAttachments: docXEntity ? [docXEntity.Guid] : [],
							getAllDocuments: true, // always refresh to get the newly added docXEntity
							email: emailPrefill,
							returnEmailObject: true,
							onNewDocument: onNewDocument
						};
					}
				}
			}).result.then(function (alarmLog) {
				// Issue purchase order
				$scope.reloadAlarmLogTable = true;
				var postModel = angular.copy($scope.model);
				postModel.RequestAction = 'Issue';
				postModel.AlarmLog = alarmLog;
				var success = function (result) { updateSuccess(result, 'purchaseOrder.edit'); };

				repository.updateSingleDictionary(repository.apiData.purchaseOrder.url, postModel).then(success, updateError);
			});
		};

		$scope.print = function () {
			$localStorage.reportParams = JSON.stringify({
				Guid: $scope.model.Guid
			});
			var reportId = $scope.model.GuidPurchaseOrderForm ? $scope.model.PurchaseOrderForm.Report.Id : 'PrintPurchaseOrder';
			var url = $scope.navigation.href('report.print', { reportName: reportId });
			$window.open(url, '_blank');
		};

		$scope.sendEmail = function () {
			var onNewDocument = function () {
				$scope.forceReloadDocumentTable = false;
				$timeout(function () {
					$scope.forceReloadDocumentTable = true;
				}, 250);
			};

			$modal.open({
				templateUrl: 'app/common/views/emailModal.html',
				controller: 'EmailModalController',
				size: 'lg',
				resolve: {
					params: function () {
						return {
							entity: 'PurchaseOrder',
							columns: columns,
							model: $scope.model,
							attachments: $scope.documents,
							getAllDocuments: $scope.counts.documentTotalCount > 10,
							onNewDocument: onNewDocument
						};
					}
				}
			}).result.then(function () {
				$scope.reloadAlarmLogTable = true;
			});
		};

		$scope.$on($scope.events.newSelection, function () {
			updateAccess();
		});

		$scope.showPurchaseButton = function () {
			return $localStorage.generalOptions.ActivatePurchaseOrderInWeb && $scope.model &&
				$scope.model.Supplier &&
				$scope.isUpdate &&
				!$scope.restrictEdit &&
				!$scope.model.OrderingDate &&
				!$scope.isPurchaseOrderClosedViaProject &&
				$scope.purchaseOrderItemTable && $scope.purchaseOrderItemTable.data && $scope.purchaseOrderItemTable.data.length > 0;
		};

		$scope.canEditPurchaseOrderItem = function (purchaseOrderItem) {
			return purchaseOrderItem && $scope.isUpdate && !$scope.restrictEdit;
		};

		var getMenuLink = function (url, icon, text) {
			return {
				html: '<a class="dropdown-item" tabindex= "-1" href="' + url + '" ><i class="zmdi ' + icon + ' zmdi-hc-fw zmdi-hc-lg"></i> ' + text + '</a>',
				isHref: true
			};
		};

		$scope.canReceiveItem = function (item) {
			return item.Status === 'Ordered' || item.Status === 'PartiallyReceived';
		};

		$scope.contextMenuOptions = function (item) {
			var options = [];

			if ($scope.hasEditAccess("PurchaseOrderItem")) {
				options.push({
					html: '<a class="dropdown-item" tabindex= "-1" href= "#"><i class="zmdi zmdi-edit zmdi-hc-fw zmdi-hc-lg"></i> ' + translationService.translate('web-workorder-purchaseorderitem-edit', 'Rediger konteringsobjekt') + '</a >',
					click: function ($itemScope, $event, modelValue, text, $li) {
						$scope.updatePurchaseOrderItem(item.Guid);
					}
				});
			}

			if ($scope.hasDeleteAccess('PurchaseOrderItem') && item.Status === 'Proposal') {
				options.push({
					html: '<a class="dropdown-item" tabindex= "-1" href= "#"><i class="zmdi zmdi-delete zmdi-hc-fw zmdi-hc-lg"></i> ' + translationService.translate('web-workorder-purchaseorderitem-delete', 'Slett konteringsobjekt') + '</a >',
					click: function ($itemScope, $event, modelValue, text, $li) {
						$scope.removePurchaseOrderItem(item.Guid);
					}
				});
			}

			if (item.GuidWorkOrder && repository.enableWebNavigation) {
				options.push(getMenuLink($rootScope.navigation.href('workOrder.edit', { guid: item.GuidWorkOrder }), 'zmdi-case', translationService.translate('web-listview-open-workorder', 'Åpne arbeidsordre')));
			}

			if ($scope.hasCreateAccess('Cost') &&
				item.Status !== 'Proposal' &&
				item.Status !== 'Closed') {
				options.push({
					html: '<a class="dropdown-item" tabindex= "-1" href= "#"><i class="zmdi zmdi-receipt zmdi-hc-fw zmdi-hc-lg"></i> ' + translationService.translate('web-workorder-purchaseorderitem-createCost', 'Legg til kostnad') + '</a >',
					click: function ($itemScope, $event, modelValue, text, $li) {
						var persistedData = {};
						persistedData.GuidPurchaseOrderItem = item.Guid;
						repository.persistedData.setPersistedData('cost.create', persistedData);
						$scope.$parent.navigation.go('cost.create', { showPersistantData: true }, { reload: true });
					}
				});
			}

			
			if ($scope.hasEditAccess("PurchaseOrderItem") && $scope.canReceiveItem(item)) {
				options.push({
					html: '<a class="dropdown-item" tabindex= "-1" href= "#"> <i class="zmdi zmdi-redo zmdi-hc-fw zmdi-hc-lg"></i>' + translationService.translate('web-workorder-purchaseorderitem-receive-partial', 'Registrer delmottak...') + '</a >',
					click: function ($itemScope, $event, modelValue, text, $li) {
						$scope.receiveItemPartially(item.Guid);
					}
				});
			}

			return options;
		};
	}
})();
