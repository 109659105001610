(function () {
	angular.module('Plania').controller('AreaCategoryController', ['$scope', 'Repository', '$stateParams', 'TranslationService', 'ListService', controller]);

	function controller($scope, repository, $stateParams, translationService, listService) {
		$scope.isUpdate = $scope.navigation.current.name === 'areaCategory.edit';
		$scope.model = { Guid: $stateParams.guid, DynamicProperty: [] };
		$scope.activateAutocomplete = false;
		$scope.viewMode = $scope.isUpdate ? true : false;

		$scope.toggleViewMode = function () {
			$scope.viewMode = !$scope.viewMode;
		};

		var updateAccess = function () {
			if ($scope.isUpdate) {
				var checkOtherDo = $scope.model.GuidDataOwner !== repository.commonService.getFilterData().selectedDataOwner.Guid;
				$scope.restrictEdit = !repository.authService.hasEditAccess(repository.commonService.prefix.AreaCategory, checkOtherDo);
				$scope.restrictDelete = !repository.authService.hasDeleteAccess(repository.commonService.prefix.AreaCategory, checkOtherDo);
			} else {
				$scope.restrictEdit = !repository.authService.hasCreateAccess(repository.commonService.prefix.AreaCategory);
			}
		};

		updateAccess();

		if ($scope.isUpdate) {
			var columns = [];
			repository.getSingle(repository.apiData.areaCategory.url, $stateParams.guid, JSON.stringify(columns)).then(
				function (response) {
					$scope.model = response.Data;
					updateAccess();
					setTimeout(function () { $scope.activateAutocomplete = true; }, 250);

				},
				function (error) {
					repository.growl(error, 'danger');
					$scope.activateAutocomplete = true;
				}
			);
		} else {
			//default values on create ?
		}

		var updateSuccess = function (result, destination) {
			repository.growl($scope.isUpdate ? translationService.translate('update-areaCategory-success', 'Kategori har blitt oppdatert.') : translationService.translate('create-areaCategory-success', 'Kategori har blitt opprettet.'), 'success');
			var guid = $scope.isUpdate ? $scope.model.Guid : result.Guid;
			repository.commonService.setLastRegisterGuid(destination, guid);
			$scope.goBack(destination, { guid: guid, menuGuid: $scope.navigation.params.menuGuid });
		};

		var updateError = function (error) {
			if (typeof (error) === "string")
				repository.growl(error, 'danger');
			else
				repository.growl(error.Data.Message, 'danger');
		};

		$scope.update = function (destination) {
			var success = function (result) { updateSuccess(result, destination); };
			if ($scope.isUpdate)
				repository.updateSingleDictionary(repository.apiData.areaCategory.url, $scope.model).then(success, updateError);
			else
				repository.createSingleDictionary(repository.apiData.areaCategory.url, $scope.model).then(success, updateError);
		};

		$scope.delete = function () {
			swal({
				title: translationService.translate('web-swal-error-areyousure', 'Er du sikker?'),
				text: translationService.translate('web-swal-areaCategory-message', "Kategori vil bli permanent fjernet!"),
				type: "warning",
				showCancelButton: true,
				confirmButtonColor: "#f44336",
				confirmButtonText: translationService.translate('web-swal-areaCategory-button-confirm', 'Ja, fjern kategori'),
				cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
				closeOnConfirm: false,
				showLoaderOnConfirm: true
			}, function () {
				window.onkeydown = null;
				window.onfocus = null;
					repository.deleteSingle(repository.apiData.areaCategory.url, $scope.model.Guid)
					.then(function (result) {
						swal(translationService.translate('web-swal-areaCategory-success', 'Kategori ble fjernet!'), result, "success");
						$scope.goBack('areaCategory.list', { menuGuid: $scope.navigation.params.menuGuid });
					});
			});
		};
	}
})();

