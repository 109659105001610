(function () {
	angular.module('Plania').controller('ConditionConfirmVersionModalController', ['$scope', 'Repository', 'modalParams', '$modalInstance', controller]);

	function controller($scope, repository, modalParams, $modalInstance) {
		$scope.VersionComment = "";
		$scope.isRunningAction = false;

		var isToday = function (testDate) {
			if (!testDate || testDate === "") return;
			var d = new Date(testDate);

			var today = new Date();
			return d.getDate() === today.getDate() &&
				d.getMonth() === today.getMonth() &&
				d.getFullYear() === today.getFullYear();
		};

		$scope.showConfirmWithConditionDateAsTodayButton = modalParams.model && !isToday(modalParams.model.ConditionDate) && (modalParams.model.Type === 'CurrentConditionInfo' || (modalParams.model.Type === 'CorrectiveAction' && !!modalParams.model.GuidConditionType));

		$scope.confirmVersion = function (setConditionDateAsToday) {
			if (modalParams.model) {
				$scope.isRunningAction = true;
				var modelCopy = angular.copy(modalParams.model);

				if (setConditionDateAsToday)
					modelCopy.ConditionDate = new Date().toISOString();

				modalParams.formatFunction(modelCopy);

				repository.updateSingleDictionary(repository.apiData.condition.url, modelCopy).then(function (result) {
					repository.runAction(repository.apiData.condition.endpoint.confirmVersion + modalParams.guid, $scope.VersionComment, null, false).then(function (result) {
						$scope.isRunningAction = false;
						$modalInstance.close();
					}, function (error) { $scope.isRunningAction = false; repository.growl(error, 'danger'); });
				}).catch(function (error) {
					$scope.isRunningAction = false;
					repository.growl(error, 'danger');
				});
			}
		};

		$scope.cancel = function () {
			$modalInstance.dismiss('canceled');
		};
	}
})();
